import React from 'react';
import thumb from '../../assets/images/limena/cobertura.png';
import iconOne from '../../assets/images/limena/adn1.png';
import iconTwo from '../../assets/images/limena/adn2.png';
import iconThree from '../../assets/images/limena/adn3.png';
import iconFour from '../../assets/images/limena/adn4.png';
import teamone from '../../assets/images/limena/limenaqs1.jpeg'
import teamtwo from '../../assets/images/limena/limenaqs2.jpeg'
import teamthree from '../../assets/images/limena/limenaqs3.jpeg'
import teamfour from '../../assets/images/limena/limenaqs4.jpg'
function DetailsService() {
    return (
        <>
            <section className="appie-service-area appie-service-3-area pt-0 pb-50" id="service">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">NUESTRO ADN</h3>
                               
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                wow
                animated
                fadeInUp
              "
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <img src={iconOne} alt="" className='iconimg'/>
                                </div>
                                <h4 className="appie-title">Atención al cliente
</h4>
                             
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <img src={iconTwo} alt="" className='iconimg'/>
                                </div>
                                <h4 className="appie-title">Mejora continua
</h4>
                               
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-3
                wow
                animated
                fadeInUp
              "
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <img src={iconThree} alt="" className='iconimg'/>
                                </div>
                                <h4 className="appie-title">Innovación</h4>
                              
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-4
                wow
                animated
                fadeInUp
              "
                                data-wow-duration="2000ms"
                                data-wow-delay="800ms"
                            >
                                <div className="icon">
                                    <img src={iconFour} alt="" className='iconimg'/>
                                </div>
                                <h4 className="appie-title">Productividad</h4>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="appie-service-details-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="service-details-sidebar mr-50">
                                <div className="service-category-widget">
                                    <ul>
                                        <li>
                                            <i className="fal fa-home"></i> 7 Estados
                                        </li>
                                        <li>
                                            <i className="fal fa-tag"></i>+ 1200 Puntos de Venta
                                        </li>
                                        <li>
                                            <i className="fal fa-user-friends"></i>16 Vendedores
                                        </li>
                                        <li>
                                            <i className="fal fa-bookmark"></i>90 Marcas
                                        </li>
                                        <li>
                                            <i className="fal fa-globe"></i>+ 900 Productos
                                        </li>
                                    </ul>
                                </div>
                                {/* <div className="service-download-widget">
                                    <a href="#">
                                        <i className="fal fa-download"></i>
                                        <span>Descarga nuestra presentacion</span>
                                    </a>
                                </div>
                                <div className="service-download-widget">
                                    <a href="#">
                                        <i className="fal fa-file-pdf"></i>
                                        <span>Servicios</span>
                                    </a>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="service-details-content">
                                <div className="thumb">
                                    <img src={thumb} alt="" />
                                </div>
                                <div className="content">
                                    <h3 className="title">¿POR QUÉ NOSOTROS?</h3>
                                    <p>
                                    EQUIPO PROFESIONAL CAPACITADO PARA OFRECER RESPUESTA OPORTUNA
                                    
                                    </p>
                                    <hr className="hrcompetencias2"/>
                                    <p>
                                    MOTIVADOS EN OFRECER UN SERVICIO EXCEPCIONAL SIEMPRE
                                    </p>
                                    <hr className="hrcompetencias2"/>
                                    <p>ADAPTABILIDAD PARA OFRECER LAS MEJORES PRÁCTICAS SIEMPRE</p>  <hr className="hrcompetencias2"/>
                                    <p>INNOVACIÓN A TRAVÉS DE LA TECNOLOGÍA PARA OFRECER MEJORES SERVICIOS</p>  <hr className="hrcompetencias2"/>
                                </div>
                                <div className="row">
                                <div className="col-md-6">
                            <div
                                className="appie-blog-item mt-30 wow animated fadeInUp"
                                data-wow-duration="3000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={teamone} alt="" />
                                </div>
                             
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div
                                className="appie-blog-item mt-30 wow animated fadeInUp"
                                data-wow-duration="3000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={teamtwo} alt="" />
                                </div>
                             
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div
                                className="appie-blog-item mt-30 wow animated fadeInUp"
                                data-wow-duration="3000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={teamthree} alt="" />
                                </div>
                             
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div
                                className="appie-blog-item mt-30 wow animated fadeInUp"
                                data-wow-duration="3000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={teamfour} alt="" />
                                </div>
                             
                            </div>
                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DetailsService;
